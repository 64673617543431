import { useState, useEffect } from "react";

/* 
  A custom hook that return the current language displayed on the website
*/
function useLanguage() {
  const [path, setPath] = useState("");

  useEffect(() => {
    setPath(window.location.pathname);
  }, []);

  if (path.includes("/zh-hant")) return "tc";
  if (path.includes("/zh-hans")) return "sc";
  return "en";
}

export default useLanguage;
