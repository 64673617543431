import React, { useContext, useState, useRef } from "react";
import { useStaticQuery, graphql, navigate } from "gatsby";
import { useTheme } from "@material-ui/core/styles";
import {
  AppBar,
  Container,
  Grid,
  IconButton,
  Snackbar,
  Toolbar,
} from "@material-ui/core";
import { alpha, makeStyles } from "@material-ui/core/styles";
import { Button, Link } from "gatsby-theme-material-ui";
import SearchIcon from "@material-ui/icons/Search";

import useLanguage from "../hooks/useLanguage";
import usePageUri from "../hooks/usePageUri";

import i18nContext from "../i18n-context";
import InputBase from '@material-ui/core/InputBase';

const Header = ({ pageTranslations }) => {
  const classes = useStyles();
  const language = useLanguage();
  const i18n = useContext(i18nContext);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [searchInputOpen, setIsearchInputOpen] = useState(false);
  const searchInputField = useRef(null)

  const langSwitch = {
    enUrl: pageTranslations?.filter(
      (translation) =>
        !translation.uri.includes("/zh-hant") &&
        !translation.uri.includes("/zh-hans")
    )[0]?.uri,
    tcUrl: pageTranslations?.filter((translation) =>
      translation.uri.includes("/zh-hant")
    )[0]?.uri,
    scUrl: pageTranslations?.filter((translation) =>
      translation.uri.includes("/zh-hans")
    )[0]?.uri,
  };

  const pages = useStaticQuery(graphql`
    query headerQuery {
      allWpPage(
        filter: { pageType: { pageType: { in: ["2", "3", "4", "5", "6"] } } }
        sort: { fields: pageType___pageType, order: ASC }
      ) {
        nodes {
          id
          title
          uri
          pageType {
            pageType
          }
          locale {
            locale
          }
        }
      }
    }
  `).allWpPage.nodes.filter((node) => {
    if (language === "en") return node.locale.locale === "en_US";
    if (language === "tc") return node.locale.locale === "zh_TW";
    if (language === "sc") return node.locale.locale === "zh_CN";
    return node.locale.locale === "en_US";
  });

  const isTc = language === "tc";
  const isSc = language === "sc";

  let data = usePageUri();
  let homepagePath = getUri(data.home, isTc, isSc);
  let subscribePath = getUri(data.subscribe, isTc, isSc);

  let enPath = getUri(data.home, false, false);
  let tcPath = getUri(data.home, true, false);
  let scPath = getUri(data.home, false, true);

  pageTranslations =
    pageTranslations &&
      Array.isArray(pageTranslations) &&
      pageTranslations.length > 0
      ? pageTranslations
      : [];

  pageTranslations.forEach((translation) => {
    let tmpUri = translation.uri;
    tmpUri.includes("/zh-hant")
      ? (tcPath = tmpUri)
      : tmpUri.includes("/zh-hans")
        ? (scPath = tmpUri)
        : (enPath = tmpUri);
  });

  const handleKeyDown = (event)=>{
    if (event.key === 'Enter') {
      doSearch()
    }
  }

  const searchButtonClick = () => {
    if (!searchInputOpen) {
      searchInputField.current.focus()
      setIsearchInputOpen(true)
    } else {
      doSearch()
    }
  };

  const searchButtonNotFocus = () => {
    setIsearchInputOpen(false)
  }

  const doSearch = () => {
    setIsearchInputOpen(false)
    let searchUrl = '/search?search='+ encodeURIComponent(searchInputField.current.value);
    searchUrl = isTc ? "/zh-hant" + searchUrl : isSc ? "/zh-hans" + searchUrl : searchUrl
    navigate(searchUrl)
  }

  return (
    <div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={2000}
        message={i18n.languageNotAvailable}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      />
      <Toolbar className={classes.appbar} />
      <AppBar elevation={0} className={classes.appbar} id="appbar">
        <Toolbar>
          <Grid container direction="column" justifyContent="center">
            <Container disableGutters>
              <Grid
                item
                container
                justifyContent="space-between"
                alignItems="baseline"
                className={classes.upperBar}
              >
                <Link
                  to={homepagePath}
                  className={classes.heading}
                  underline="none"
                >
                  <img
                    src={isTc || isSc ? "/logo-chi.png" : "/logo.png"}
                    alt=""
                  />
                </Link>

                <div>
                  <div className={classes.search}>
                    <InputBase
                      placeholder={i18n.searchPage.search+"..."}
                      classes={{
                        root: classes.inputRoot,
                        input: classes.inputInput,
                      }}
                      inputProps={{ 'aria-label': 'search' }}
                      onKeyDown={handleKeyDown}
                      inputRef={searchInputField}
                    />
                    <IconButton className={`${classes.iconButton} ${classes.smallIcon}`} 
                      aria-label="search" 
                      onClick={()=>{searchButtonClick()}}
                      onBlur={()=>{searchButtonNotFocus()}}
                      >
                      <SearchIcon />
                    </IconButton>
                  </div>
                  <Button
                    variant="contained"
                    disableElevation
                    className={classes.subscribeButton}
                    to={subscribePath}
                  >
                    {i18n.subscribe}
                  </Button>
                  {(isTc || isSc) && (
                    <Button
                      key={`lang-switch-en`}
                      className={classes.langButton}
                      to={langSwitch.enUrl}
                      onClick={() => {
                        if (!langSwitch.enUrl) setSnackbarOpen(true);
                      }}
                    >
                      EN
                    </Button>
                  )}
                  {!isTc && (
                    <Button
                      key={`lang-switch-zh`}
                      className={classes.langButton}
                      to={langSwitch.tcUrl}
                      onClick={() => {
                        if (!langSwitch.tcUrl) setSnackbarOpen(true);
                      }}
                    >
                      繁體
                    </Button>
                  )}
                  {!isSc && (
                    <Button
                      key={`lang-switch-sc`}
                      className={classes.langButton}
                      to={langSwitch.scUrl}
                      onClick={() => {
                        if (!langSwitch.scUrl) setSnackbarOpen(true);
                      }}
                    >
                      简体
                    </Button>
                  )}
                </div>
              </Grid>
            </Container>

            <Grid
              container
              justifyContent="center"
              className={classes.lowerBar}
            >
              {pages.map((page) => {
                return (
                  <Button
                    key={`navbar-button-${page.title}-${language}`}
                    className={classes.navButton}
                    to={page.uri}
                  >
                    {page.title}
                  </Button>
                );
              })}
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </div>
  );
};

const getUri = (data, isTc, isSc) => {
  let lang = isTc ? "zh_TW" : isSc ? "zh_CN" : "en_US";
  let filterReust = data.filter((item) => item.locale === lang);
  return filterReust[0] ? decodeURIComponent(filterReust[0].uri) : "/";
};

const useStyles = makeStyles((theme) => ({
  appbar: {
    minHeight: "132px",
  },
  upperBar: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  heading: {
    color: "white",
    "&:hover": {
      cursor: "pointer",
    },
    "& img": {
      height: 28,
    },
  },
  langButton: {
    minWidth: "fit-content",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    color: "white",
  },
  lowerBar: {
    marginBottom: theme.spacing(1),
  },
  navButton: {
    color: "#ffffff",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  subscribeButton: {
    color: "white",
    backgroundColor: theme.palette.error.main,
    borderRadius: 0,

    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),

    "&:hover": {
      color: "white",
      backgroundColor: theme.palette.error.main,
    },
  },


  search: {
    display: 'inline-block',
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
    alignItems: 'center',
  },
  searchIcon: {
    color: "#ffffff",
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    fontSize: "18px",
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '0ch',
      '&:focus': {
        width: '12ch',
      },
    },
  },
  iconButton: {
    color: "#ffffff",
  },
}));

export default Header;
