import React from "react";
import "./Hamburger.css";

const Hamburger = ({ open, onClick }) => {
  const handleClick = () => {
    onClick();
  };

  return (
    <>
      <div
        className={`nav-icon ${open && "open"}`}
        onClick={handleClick}
        onKeyDown={handleClick}
        role="button"
        tabIndex={0}
      >
        <span></span>
        <span></span>
        <span></span>
      </div>
    </>
  );
};

export default Hamburger;
