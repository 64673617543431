import React from "react";

const enContext = {
  websiteName: "FUNG BUSINESS INTELLIGENCE",

  footer: {
    usefulLinks: "Links",
    theFungGroup: "The Fung Group",
    LiFungTrading: "Li & Fung Limited",
    theFungGroupLink: "https://funggroup.com/en/home",
    LiFungTradingLink: "https://www.lifung.com",
    copyrightText: `Copyright © ${new Date().getFullYear()} Fung Business Intelligence. All Rights Reserved.`,
    sitemap: "Sitemap",
    sitemapUrl: "/sitemap",
  },

  sectionHeadings: {
    publications: "Publications",
    books: "Books",
    speeches: "Speeches",
    periodicals: "Periodicals",
    latestPeriodicals: "Latest Periodicals",
    trendingTopics: "Trending Topics",
    browseByYear: "Browse by year:",
  },

  contentPage: {
    backTo: "Back to ",
    backToEvent: "Back to Event",
    downloadFullReport: "Download Full Report",
    downloadPdf: "Download PDF",
    share: "Share",
    selectSharingChannel: "Select sharing channel:",
    shareToWeChat: {
      shareToWeChat: "Share to WeChat",
      copyLinkToClipBoard: "Copy link to clipboard",
      done: "Done",
    },
    articleTags: "Article tags",
    event: "Event",
    author: "Author:",
    pubDate: "Publication Date:",
    language: "Language:",
    purchase: "Purchase",
  },

  contact: {
    contactName: "Full Name",
    contactEmail: "Contact Email",
    message: "Message",
    send: "Send",
    ourOffice: "Our Office",
    submitResultSuccess: "Thank you for contacting us. We will respond to your inquiry as soon as possible.",
    submitResultFail: "Send message Fail.",
  },

  searchPage: {
    search: "Search",
    title: "You searched for",
    refine: "Refine Search",
    clearAll: "Clear All",
    cancel: "Cancel",
    results: " Results",
    noMatch: "Sorry your search did not return any results matching:",
    haveYouTried: "Have you tried",
    tips1: "1) searching in the webpage's corresponding language",
    tips2: "2) using a comma to separate words for a more inclusive search",
    tips3: "3) or contact <a href='mailto:fbicgroup@fung1937.com'>fbicgroup@fung1937.com</a> to help you find what you are looking for",
    sort: "Sort By ",
    anonymous: "Anonymous",
    status: {
      status: "Status",
      is_new: "New",
      is_featured: "Featured",
      is_archived: "Archived",
    },
    publication_date: {
      publication: "Publication Date",
      last_1_month: "Last 1 Month",
      last_3_month: "Last 3 Month",
      last_6_month: "Last 6 Month",
    },
    content_type: {
      content_type: "Content Type",
      periodical: "Periodical",
      report: "Report",
      book: "Book",
      speech: "Speech",
      press_release: "Press Release",
      event: "Event",
    },
    sortList: {
      new: "Newest",
      old: "Oldest",
      relevance: "Relevance",
    },
    topics: "Topic",
    geographies: "Geography",
    research_categories: "Research Category",
    report_series: "Report Series",
  },

  loading: "Loading...",

  subscribe: "Subscribe",
  subscribeForm: {
    subscribe: "Subscribe",
    firstName: "First Name",
    lastName: "Last Name",
    email: "Email",
    jobTitle: "Job Title",
    company: "Company",

    areaOfInterest: "Area of Interest",
    businessModelInnovationAndTechnology:
      "Business Model Innovation and Technology",
    logistics: "Logistics",
    retailAndEcommerce: "Retail and E-commerce",
    sourcing: "Sourcing",
    tradeAndEconomy: "Trade and Economy",
    supplyChain: "Supply Chain",

    sending: "Sending...",
    success: "Thank you for your subscription!",
    submit: "Submit",
  },

  media:{
    mediaCoverage: "Media Coverage",
    pressRelease: "Press Releases",
  },
  viewAll:"View All",

  languageNotAvailable: "Selected language is not available",

  showMore: "Show More",

  noContentIsAvailable: "No content is available",

  notFound: {
    title: "404: Not Found",
    message: "The page was not found, please check the link and try again.",
  },

  anchor:{
    publications: "publications",
    books: "books",
    speeches: "speeches",
    mediaCoverage: "media-coverage",
    pressRelease: "press-releases",
  }
};

const tcContext = {
  websiteName: "馮氏集團利豐研究中心",

  footer: {
    ...enContext.footer,
    usefulLinks: "連結",
    theFungGroup: "馮氏集團",
    LiFungTrading: "利豐有限公司",
    theFungGroupLink: "https://funggroup.com/zh/home",
    copyrightText: `${new Date().getFullYear()} Copyright© 馮氏集團利豐研究中心 版權所有`,
    sitemap: "網站地圖",
    sitemapUrl: "/zh-hant/sitemap",
  },

  sectionHeadings: {
    publications: "刊物",
    books: "書籍",
    speeches: "演講",
    periodicals: "期刊",
    latestPeriodicals: "最新期刊",
    trendingTopics: "熱點話題",
    browseByYear: "按年份檢索：",
  },

  contentPage: {
    ...enContext.contentPage,
    backTo: "回到",
    backToEvent: "回到 活動",
    downloadFullReport: "下載完整報告",
    downloadPdf: "下載附件",
    share: "分享",
    selectSharingChannel: "選擇分享平台：",
    shareToWeChat: {
      shareToWeChat: "分享到微信",
      copyLinkToClipBoard: "複製連結",
      done: "完成",
    },
    articleTags: "文章標簽",
    event: "活動",
    author: "作者：",
    pubDate: "發布日期：",
    language: "語言：",
    purchase: "購買",
  },

  contact: {
    ...enContext.contact,
    contactName: "姓名",
    contactEmail: "電郵",
    message: "訊息",
    send: "傳送",
    ourOffice: "我們的辦公室",
    submitResultSuccess: "謝謝你的留言，我們會盡快跟進",
    submitResultFail: "發送信息失敗。",
  },

  searchPage: {
    title: "您正搜索",
    search: "搜尋",
    refine: "詳細搜尋",
    clearAll: "清除全部",
    cancel: "取消",
    results: "結果",
    noMatch: "抱歉，我們找不到你所需的字詞。",
    haveYouTried: "你還可以嘗試:",
    tips1: "1) 利用此網站相應的語言搜尋所需的字詞",
    tips2: "2) 用逗號分隔關鍵詞，獲取更多搜尋結果",
    tips3: "3) 透過<a href='mailto:fbicgroup@fung1937.com'>fbicgroup@fung1937.com</a>聯繫我們",
    sort: "排序方式",
    anonymous: "匿名",
    status: {
      status: "狀態",
      is_new: "最新",
      is_featured: "特色",
      is_archived: "檔案",
    },
    publication_date: {
      publication: "發表",
      last_1_month: "1個月內",
      last_3_month: "3個月內",
      last_6_month: "6個月內",
    },
    content_type: {
      content_type: "類型",
      periodical: "期刊",
      report: "報告",
      book: "書籍",
      speech: "演講",
      press_release: "新聞稿",
      event: "活動",
    },
    sortList: {
      new: "按發布時間降序",
      old: "按發布時間升序",
      relevance: "按相關性",
    },
    topics: "主題",
    geographies: "地理",
    research_categories: "研究類別",
    report_series: "報告系列",
    noResult: "無搜尋結果",
  },

  loading: "載入中...",

  subscribe: "訂閱",
  subscribeForm: {
    subscribe: "訂閱",
    firstName: "姓氏",
    lastName: "名稱",
    email: "電郵地址",
    jobTitle: "職位",
    company: "公司",

    areaOfInterest: "感興趣的領域",
    businessModelInnovationAndTechnology: "商業模式創新與科技",
    logistics: "物流",
    retailAndEcommerce: "零售及電商",
    sourcing: "採購",
    tradeAndEconomy: "貿易及經濟",
    supplyChain: "供應鏈",

    sending: "處理中...",
    success: "感謝您的訂閱！",
    submit: "提交",
  },

  media:{
    mediaCoverage: "媒體報道",
    pressRelease: "新聞稿",
  },
  viewAll:"查看全部",

  languageNotAvailable: "此頁面並不支援所選語言",

  showMore: "閱讀更多",

  noContentIsAvailable: "未有提供",

  notFound: {
    title: "404: 頁面不存在",
    message: "抱歉，您訪問的頁面不存在，建議您檢查一下再試。",
  },

  anchor:{
    // publications: "刊物",
    // books: "書籍",
    // speeches: "演講",
    // mediaCoverage: "媒體報道",
    // pressRelease: "新聞稿",
    ...enContext.anchor,
  }
};

const scContext = {
  websiteName: "冯氏集团利丰研究中心",

  footer: {
    ...tcContext.footer,
    usefulLinks: "链接",
    theFungGroup: "冯氏集团",
    LiFungTrading: "利丰有限公司",
    theFungGroupLink: "https://funggroup.com/cn/home",
    copyrightText: `${new Date().getFullYear()} Copyright© 冯氏集团利丰研究中心 版权所有`,
    sitemap: "网站地图",
    sitemapUrl: "/zh-hans/sitemap",
  },

  sectionHeadings: {
    ...tcContext.sectionHeadings,
    books: "书本",
    speeches: "演讲",
    trendingTopics: "热点话题",
    browseByYear: "按年份检索：",
  },

  contentPage: {
    ...tcContext.contentPage,
    backTo: "回到",
    backToEvent: "回到 活动",
    downloadFullReport: "下载完整报告",
    downloadPdf: "下载附件",
    share: "转发",
    selectSharingChannel: "选择分享平台：",
    shareToWeChat: {
      shareToWeChat: "转发至微信",
      copyLinkToClipBoard: "复制连接",
      done: "完成",
    },
    articleTags: "文章标签",
    event: "活动",
    author: "作者：",
    pubDate: "发布日期：",
    language: "语言：",
    purchase: "购买",
  },

  contact: {
    ...tcContext.contact,
    send: "传送",
    contactEmail: "电邮",
    ourOffice: "我们的办公室",
    submitResultSuccess: "谢谢你的留言，我们会尽快跟进",
    submitResultFail: "发送信息失败。",
  },

  searchPage: {
    search: "搜索",
    title: "您正在搜索",
    refine: "详细搜寻",
    clearAll: "清除全部",
    cancel: "取消",
    results: "结果",
    noMatch: "抱歉，您的检索结果为空。",
    haveYouTried: "您还可以尝试:",
    tips1: "1）在其他语言网页中继续搜索",
    tips2: "2）用逗号分隔检索关键词，获取更多检索结果",
    tips3: "3）通过<a href='mailto:fbicgroup@fung1937.com'>fbicgroup@fung1937.com</a>联系我们",
    sort: "排序方式",
    anonymous: "匿名",
    status: {
      status: "状态",
      is_new: "最新",
      is_featured: "特色",
      is_archived: "档案",
    },
    publication_date: {
      publication: "发表",
      last_1_month: "1個月內",
      last_3_month: "3個月內",
      last_6_month: "6個月內",
    },
    content_type: {
      content_type: "类型",
      periodical: "期刊",
      report: "报告",
      book: "书籍",
      speech: "演讲",
      press_release: "新闻稿",
      event: "活动",
    },
    sortList: {
      new: "按发布时间降序",
      old: "按发布时间升序",
      relevance: "按相关性",
    },
    topics: "主题",
    geographies: "地理",
    research_categories: "研究类别",
    report_series: "报告系列",
    noResult: "無搜寻结果",
  },

  loading: "载入中...",

  subscribe: "订阅",
  subscribeForm: {
    subscribe: "订阅",
    firstName: "姓氏",
    lastName: "名称",
    email: "电邮地址",
    jobTitle: "职位",
    company: "公司",

    areaOfInterest: "感兴趣的领域",
    businessModelInnovationAndTechnology: "商业模式创新与科技",
    logistics: "物流",
    retailAndEcommerce: "零售及电商",
    sourcing: "采购",
    tradeAndEconomy: "贸易及经济",
    supplyChain: "供应链",

    sending: "处理中...",
    success: "感谢您的订阅！",
    submit: "提交",
  },

  media:{
    mediaCoverage: "媒体报道",
    pressRelease: "新闻稿",
  },
  viewAll:"查看全部",

  languageNotAvailable: "此页面并不支援所选语言",

  showMore: "阅读更多",

  notFound: {
    title: "404: 页面不存在",
    message: "抱歉，您访问的页面不存在，建议您检查一下再试。",
  },

  anchor:{
    // publications: "刊物",
    // books: "书本",
    // speeches: "演讲",
    // mediaCoverage: "媒体报道",
    // pressRelease: "新闻稿",
    ...enContext.anchor,
  }
};

export const i18n = {
  en: enContext,
  tc: tcContext,
  sc: scContext,
};

const i18nContext = React.createContext(i18n);
export const I18nProvider = i18nContext.Provider;
export default i18nContext;
