import React, { useState, useRef, useContext } from "react";
import { useStaticQuery, graphql, navigate } from "gatsby";
import {
  AppBar,
  Divider,
  Grid,
  Toolbar,
  List,
  ListItem,
  ListItemText,
  Slide,
  Snackbar,
  Paper,
  useMediaQuery,
  useTheme,
  Container,
  InputBase,
  IconButton
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Link } from "gatsby-theme-material-ui";
import Hamburger from "./Hamburger";
import SearchIcon from "@material-ui/icons/Search";

import useLanguage from "../hooks/useLanguage";
import usePageUri from "../hooks/usePageUri";

import i18nContext from "../i18n-context";

const MobileHeader = ({ pageTranslations }) => {
  const i18n = useContext(i18nContext);
  const classes = useStyles();
  const anchorRef = useRef(null);
  const language = useLanguage();
  const [open, setOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const isPhone = useMediaQuery(useTheme().breakpoints.down("xs"));
  const isDesktop = useMediaQuery(useTheme().breakpoints.up("lg"));
  
  const [inputValue, setInputValue] = useState("");
  const [searchInputOpen, setIsearchInputOpen] = useState(false);

  const isTc = language === "tc";
  const isSc = language === "sc";

  const logo = {
    // src: "/logo-mobile.png",
    src: "/logo.png",
    alt: "",
    style: {
      // height: isPhone ? 14 : 28,
      // width: "auto",
      // maxWidth: isPhone ? `240px` : `auto`,
      transform: isDesktop ? `none` : `translateY(2px)`,
    },
  };
  if (isTc || isSc) {
    // logo.src = "/logo-chi-mobile.png";
    logo.src = "/logo-chi.png";
    // logo.style.height = isPhone ? 16 : 30;
  }

  const langSwitch = {
    enUrl: pageTranslations?.filter(
      (translation) =>
        !translation.uri.includes("/zh-hant") &&
        !translation.uri.includes("/zh-hans")
    )[0]?.uri,
    tcUrl: pageTranslations?.filter((translation) =>
      translation.uri.includes("/zh-hant")
    )[0]?.uri,
    scUrl: pageTranslations?.filter((translation) =>
      translation.uri.includes("/zh-hans")
    )[0]?.uri,
  };

  const pages = useStaticQuery(graphql`
    query mobileHeaderQuery {
      allWpPage(
        filter: { pageType: { pageType: { in: ["2", "3", "4", "5", "6"] } } }
        sort: { fields: pageType___pageType, order: ASC }
      ) {
        nodes {
          id
          title
          uri
          pageType {
            pageType
          }
          locale {
            locale
          }
        }
      }
    }
  `).allWpPage.nodes.filter((node) => {
    if (language === "en") return node.locale.locale === "en_US";
    if (language === "tc") return node.locale.locale === "zh_TW";
    if (language === "sc") return node.locale.locale === "zh_CN";
    return node.locale.locale === "en_US";
  });

  let data = usePageUri();
  let homepagePath = getUri(data.home, isTc, isSc);
  let subscribePath = getUri(data.subscribe, isTc, isSc);

  const toggleMenu = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const searchButton = () => {
    setIsearchInputOpen(!searchInputOpen)
  };

  const handleTextFieldChange = (event) => {
    setInputValue(event.target.value);
  }

  const serachIconButtonClick = () => {
    // inputValue ? changeUrl(inputValue.split(","), parameter) : changeUrl([], parameter)
    let searchUrl = "/search?search="+encodeURIComponent(inputValue);
    searchUrl = isTc ? "/zh-hant" + searchUrl : isSc ? "/zh-hans" + searchUrl : searchUrl
    navigate(searchUrl)
  }

  return (
    <>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={2000}
        message={i18n.languageNotAvailable}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      />
      <Toolbar ref={anchorRef} />
      <AppBar elevation={0} color="primary" id="appbar">
        <Toolbar>
          <Hamburger open={open} onClick={toggleMenu} />
          <Link to={homepagePath} className={classes.heading} underline="none">
            <img {...logo} alt="" />
          </Link>
          <SearchIcon className={classes.smallIcon} onClick={() => searchButton()} size="small" />
        </Toolbar>
      </AppBar>
      {searchInputOpen ? <Container>
        <Paper elevation={0} square className={classes.searchInput}>
          <InputBase
            className={classes.input}
            onChange={handleTextFieldChange}
            placeholder={i18n.searchPage.search}
          />
          <IconButton onClick={serachIconButtonClick} className={`${classes.iconButton} ${classes.smallIcon}`} aria-label="search">
            <SearchIcon />
          </IconButton>

        </Paper>
        <Divider className={classes.mobileDivider} />
      </Container> : null}
      <Slide direction="down" in={open}>
        <Paper square className={classes.slidePaper} elevation={0}>
          <List className={classes.list}>
            {pages.map((page) => {
              return (
                <ListItem
                  key={`mobile-navbar-button-${page.title}-${language}`}
                  button
                  className={classes.listItem}
                  component={Link}
                  to={page.uri}
                >
                  <ListItemText className={classes.listItemText}>
                    {page.title}
                  </ListItemText>
                </ListItem>
              );
            })}
          </List>
          <Divider className={classes.divider} variant="middle" />
          <Grid container spacing={1} className={classes.gridContainer}>
            <Grid item style={{ paddingLeft: 0 }}>
              <Button
                variant="contained"
                disableElevation
                className={classes.subscribeButton}
                to={subscribePath}
              >
                {i18n.subscribe}
              </Button>
            </Grid>
            <Grid item>
              <Divider
                orientation="vertical"
                className={classes.verticalDivider}
              />
            </Grid>
            {(isTc || isSc) && (
              <Grid item>
                <Button
                  key={`lang-switch-en`}
                  className={classes.langButton}
                  to={langSwitch.enUrl}
                  onClick={() => {
                    if (!langSwitch.enUrl) setSnackbarOpen(true);
                  }}
                >
                  EN
                </Button>
              </Grid>
            )}
            {!isTc && (
              <Grid item>
                <Button
                  key={`lang-switch-zh`}
                  className={classes.langButton}
                  to={langSwitch.tcUrl}
                  onClick={() => {
                    if (!langSwitch.tcUrl) {
                      setSnackbarOpen(true);
                      setOpen(false);
                    }
                  }}
                >
                  繁體
                </Button>
              </Grid>
            )}
            {!isSc && (
              <Grid item>
                <Button
                  key={`lang-switch-sc`}
                  className={classes.langButton}
                  to={langSwitch.scUrl}
                  onClick={() => {
                    if (!langSwitch.scUrl) {
                      setSnackbarOpen(true);
                      setOpen(false);
                    }
                  }}
                >
                  简体
                </Button>
              </Grid>
            )}
          </Grid>
        </Paper>
      </Slide>
    </>
  );
};

const getUri = (data, isTc, isSc) => {
  let lang = isTc ? "zh_TW" : isSc ? "zh_CN" : "en_US";
  let filterReust = data.filter((item) => item.locale === lang);
  return filterReust[0] ? decodeURIComponent(filterReust[0].uri) : "/";
};

const useStyles = makeStyles((theme) => ({
  heading: {
    // display: "flex",
    marginLeft: "-8px",
    color: "white",
    flex: 1,
    [theme.breakpoints.down("xs")]: {
      fontSize: 14,
    },
    "&:hover": {
      cursor: "pointer",
    },
    "& img": {
      height: "14px"
    },
  },
  slidePaper: {
    position: "fixed",
    zIndex: 1000,
    width: "100%",
    height: "fit-content",
    top: theme.mixins.toolbar.minHeight,
    backgroundColor: `rgba(51, 51, 51, 0.97)`,
    // backgroundColor: theme.palette.primary.main,
    color: "white",
  },
  list: {
    width: "100%",
    paddingTop: theme.spacing(1),
  },
  listItem: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(4),
  },
  listItemText: {
    color: "white !important",
  },
  divider: {
    backgroundColor: "white",
    overflow: "hidden",
  },
  gridContainer: {
    margin: theme.spacing(4),
  },
  subscribeButton: {
    borderRadius: 0,
    color: "white",
    backgroundColor: theme.palette.error.main,
    "&:hover": {
      backgroundColor: theme.palette.error.main,
      color: "white",
    },
  },
  verticalDivider: {
    backgroundColor: "white",
    marginLeft: theme.spacing(2),
  },
  langButton: {
    minWidth: "fit-content",
    color: "white",
  },

  searchInput: {
    marginTop: "10px",
    display: 'flex',
    alignItems: 'center',
    border: "grey 3px solid"
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  smallIcon: {
    fontSize: 22,
    [theme.breakpoints.down("xs")]: {
      fontSize: 18,
    },
  },
  iconButton: {
    padding: 10,
  },
  mobileDivider: {
    backgroundColor: "#333333",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      display: "none"
    },
  },
}));

export default MobileHeader;
