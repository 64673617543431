import React from "react";
import { Container as MuiContainer } from "@material-ui/core";
import { useMediaQuery, useTheme, makeStyles } from "@material-ui/core";

const Container = ({ children }) => {
  const classes = useStyles();
  const isDesktop = useMediaQuery(useTheme().breakpoints.up("lg"));
  const isWideMon = useMediaQuery(useTheme().breakpoints.up(1440));

  return (
    <MuiContainer
      disableGutters={isDesktop}
      maxWidth={isWideMon ? "lg" : "md"}
      className={classes.container}
    >
      {children}
    </MuiContainer>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
  },
}));

export default Container;
