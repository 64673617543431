import React, { useEffect, useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import makeStyles from "@material-ui/core/styles/makeStyles";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";

const BackToTopButton = () => {
  const [open, setOpen] = useState(false);
  const [footerHeight, setFooterHeight] = useState(0);
  const classes = useStyles(footerHeight);

  useEffect(() => {
    const handleResize = () => {
      const footerHeight = document.getElementById("footer").offsetHeight;
      const footerTop = document.getElementById("footer").offsetTop;
      let subscribeButtonTop = 0;
      if (document.getElementById("footer-subscribe-button"))
        subscribeButtonTop = document.getElementById("footer-subscribe-button")
          .offsetTop;
      setFooterHeight(footerHeight + subscribeButtonTop - footerTop);
    };

    const handleScroll = () => {
      if (!document.getElementById("back-to-top-button")) return;

      if (window.scrollY === 0) {
        setOpen(false);
        return;
      }

      if (!open) {
        setOpen(true);
        return;
      }
    };

    window.addEventListener("resize", handleResize);
    document.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("resize", handleResize);
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div id="back-to-top-button">
      {open && (
        <IconButton
          className={classes.backToTopButtonRoot}
          onClick={handleClick}
        >
          <ExpandLessIcon className={classes.icon} fontSize="large" />
        </IconButton>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  backToTopButtonRoot: {
    position: "fixed !important",
    [theme.breakpoints.down("xs")]: {
      bottom: (footerHeight) => footerHeight + 58,
      right: 4,
    },
    [theme.breakpoints.up("sm")]: {
      bottom: (footerHeight) => footerHeight + 62,
      right: 12,
    },
    [theme.breakpoints.up("md")]: {
      bottom: "245px !important",
    },
  },
  icon: {
    backgroundColor: `rgba(204, 32, 47, 0.5)`,
    color: theme.palette.primary.main,
  },
}));

export default BackToTopButton;
