import React, { useContext, useState } from "react";
import {
  Container,
  Divider,
  Dialog,
  DialogContent,
  Grid,
  Hidden,
  IconButton,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import { Button } from "gatsby-theme-material-ui";

import useLanguage from "../hooks/useLanguage";
import usePageUri from "../hooks/usePageUri";

import i18nContext from "../i18n-context";
import useWpRestApi from "../hooks/useWpRestApi";

const Footer = () => {
  const lang = useLanguage();
  const classes = useStyles();
  const muiTheme = useTheme();
  const i18n = useContext(i18nContext);
  const [dialogOpen, setDialogOpen] = useState(false);
  const isMobile = useMediaQuery(muiTheme.breakpoints.down("xs"));
  const isTablet = useMediaQuery(muiTheme.breakpoints.up("md"));
  const isDesktop = useMediaQuery(muiTheme.breakpoints.up("lg"));

  const isTc = lang === "tc";
  const isSc = lang === "sc";

  const [isExternalLinksAvailable, setExternalLinksAvailable] = useState(
    true
  );
  const externalLinks = useWpRestApi(
    "/external-links",
    setExternalLinksAvailable
  );
  const [isSocialLinksAvailable, setSocialLinksAvailable] = useState(
    true
  );
  const socialLinks = useWpRestApi(
    "/social-links",
    setSocialLinksAvailable
  );

  let data = usePageUri();
  let subscribePath = getUri(data.subscribe, isTc, isSc);

  const shareButtonIcons = [
    {
      src: "/weibo_bw.png",
      url: "https://weibo.com/u/6501893827?refer_flag=1001030103_&is_all=1",
    },
    {
      src: "/linkedin_bw.png",
      url: "https://www.linkedin.com/company/fung-business-intelligence/",
    },
    {
      src: "/twitter_bw.png",
      url: "https://twitter.com/fbicgroup",
    },
    {
      src: "/facebook_bw.png",
      url: "https://www.facebook.com/fungbusinessintelligence/",
    },
    {
      src: "/wechat_bw.png",
      onClick: () => setDialogOpen(true),
    },
  ];

  return (
    <>
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogContent>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <img
              src="/wechat_qr_code.jpg"
              alt=""
              style={{ maxWidth: "100%" }}
            />
          </Grid>
        </DialogContent>
      </Dialog>

      <Hidden mdUp>
        <Container>
          <Grid container justifyContent="flex-end">
            <Button
              id="footer-subscribe-button"
              variant="contained"
              disableElevation
              className={classes.subscribeButton}
              to={subscribePath}
            >
              {i18n.subscribe}
            </Button>
          </Grid>
        </Container>
      </Hidden>
      <Grid
        id="footer"
        container
        direction="column"
        justifyContent="center"
        className={classes.footerRoot}
      >
        <Container disableGutters={isDesktop}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="h5" className={classes.footerHeading}>
                {i18n.footer.usefulLinks}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} container alignItems="center">
              {externalLinks.map((link, index) => {
                return (
                  <Button
                    className={classes.button}
                    startIcon={<ArrowRightIcon className={classes.icon} />}
                    to={link.url}
                    target="_blank"
                  >
                    {link.name}
                  </Button>
                );
              })}
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              container
              justifyContent={isTablet ? "flex-end" : "flex-start"}
            >
              {socialLinks.map((icon, index) => {
                if (icon.onClick) {
                  return (
                    <IconButton
                      href={icon.url}
                      target="_blank"
                      onClick = {() => setDialogOpen(true)}
                      key={`footer-share-button-${index}`}
                    >
                      <img
                        src={icon.icon}
                        alt=""
                        style={{
                          objectFit: "fill",
                          width: "32px",
                          height: "auto",
                        }}
                      />
                    </IconButton>
                  )
                } else {
                  return (
                    <IconButton
                      href={icon.url}
                      target="_blank"
                      key={`footer-share-button-${index}`}
                    >
                      <img
                        src={icon.icon}
                        alt=""
                        style={{
                          objectFit: "fill",
                          width: "32px",
                          height: "auto",
                        }}
                      />
                    </IconButton>
                  );
                }
              })}
            </Grid>
            <Grid item xs={12}>
              <Divider className={classes.divider} />
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="body2"
                align={isMobile ? "center" : "left"}
                className={classes.copyright}
              >
                {`${i18n.footer.copyrightText}`} | <a className={classes.sitemapUrl} href={i18n.footer.sitemapUrl}>{i18n.footer.sitemap}</a>
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Grid>
    </>
  );
};

const getUri = (data, isTc, isSc) => {
  let lang = isTc ? "zh_TW" : isSc ? "zh_CN" : "en_US";
  let filterReust = data.filter((item) => item.locale === lang);
  return filterReust[0] ? decodeURIComponent(filterReust[0].uri) : "/";
};

const useStyles = makeStyles((theme) => ({
  footerRoot: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(6),
    backgroundColor: theme.palette.secondary.main,
    color: "white",
  },
  footerHeading: {
    fontFamily: "Open Sans",
    color: "white",
  },
  button: {
    color: "white",
    marginRight: theme.spacing(2),
    transform: `translateX(-10px)`,
    [theme.breakpoints.down("xs")]: {
      fontSize: 14,
      paddingBottom: 0,
    },
  },
  icon: {
    marginRight: -8,
  },
  divider: {
    overflow: "hidden",
    backgroundColor: "white",
    // marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up("lg")]: {
      marginLeft: `-${theme.spacing(4)}px`,
      marginRight: `-${theme.spacing(4)}px`,
    },
  },
  copyright: {
    [theme.breakpoints.down("xs")]: {
      fontSize: 10,
    },
  },
  subscribeButton: {
    color: "white",
    backgroundColor: theme.palette.error.main,
    borderRadius: 0,

    transform: `translateY(20px)`,

    "&:hover": {
      color: "white",
      backgroundColor: theme.palette.error.main,
    },
  },
  sitemapUrl: {
    color: "white",
    textDecoration: "none",

    "&:hover": {
      color: "white",
    },
  },
}));

export default Footer;
