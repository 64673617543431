import "@fontsource/roboto";
import "@fontsource/open-sans";
import "@fontsource/source-serif-pro";

import React from "react";
import ThemeTopLayout from "gatsby-theme-material-ui-top-layout/src/components/top-layout";
import theme from "../gatsby-theme-material-ui-top-layout/theme";

import { I18nProvider, i18n } from "../i18n-context";

import Hidden from "@material-ui/core/Hidden";
import Header from "./Header";
import MobileHeader from "./MobileHeader";
import Footer from "./Footer";
import BackToTopButton from "./BackToTopButton";

import useLanguage from "../hooks/useLanguage";

const Layout = ({ children, pageTranslations }) => {
  const lang = useLanguage();

  return (
    <I18nProvider value={i18n[lang]}>
      <div
        style={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}
      >
        <ThemeTopLayout theme={theme}>
          <Hidden smDown>
            <Header pageTranslations={pageTranslations} />
          </Hidden>
          <Hidden mdUp>
            <MobileHeader pageTranslations={pageTranslations} />
          </Hidden>
          {children}
          <Footer />
        </ThemeTopLayout>
        <BackToTopButton />
      </div>
    </I18nProvider>
  );
};

export default Layout;
