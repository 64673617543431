import { useStaticQuery, graphql } from "gatsby";

function usePageUri() {
  const data = useStaticQuery(graphql`
    query headersssQuery {
      allWpPage(filter: { pageType: { pageType: { in: ["1", "7"] } } }) {
        nodes {
          id
          title
          uri
          pageType {
            pageType
          }
          locale {
            locale
          }
        }
      }
    }
  `);

  let object = {
    subscribe: [],
    home: [],
  };
  data.allWpPage.nodes.forEach((item) => {
    let tmp = {
      locale: item.locale.locale,
      uri: item.uri,
    };
    item.pageType.pageType === "1"
      ? object.home.push(tmp)
      : object.subscribe.push(tmp);
  });
  return object;
}

export default usePageUri;
