import { useState, useEffect } from "react";
import moment from "moment";

const useWpRestApi = (queryString, setLoading) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    let langTag = "";
    let operator = queryString.includes("?") ? "&" : "?";

    if (window.location.pathname.includes("/zh-hant"))
      langTag = "language=zh-hant";
    if (window.location.pathname.includes("/zh-hans"))
      langTag = "language=zh-hans";

    const url = `${process.env.WP_REST_URL}${queryString}${
      langTag ? operator + langTag : ""
    }`;

    const fetchData = async () => {
      await fetch(url)
        .then((res) => res.json())
        .then((posts) => {
          if (posts.length === 0 && setLoading) setLoading(false);
          const postsWithMomentDate = posts.map((post) => {
            if (!posts.publication_date) return { ...post };
            const dateArr = post.publication_date
              .split("/")
              .reverse()
              .map((field) => parseInt(field));
            return {
              ...post,
              publication_date: moment().set({
                year: dateArr[0],
                month: dateArr[1] - 1,
                date: dateArr[2],
              }),
            };
          });
          setData(postsWithMomentDate);
        })
        .catch((error) => {
          console.log(error);
          if (setLoading) setLoading(false);
        });
    };
    fetchData();
  }, [queryString, setLoading]);

  return data;
};

export default useWpRestApi;
